import { getPage } from "./dynamic-pager.js";

const queryStringParam = '&departingWithinTwoWeeks=';
const pageClass = 'a.js-dynamic-pager-page';
const filterSelector = $('#agentTwoWeekDepartures');

const twoWeeksOnly = () => {
    filterSelector.prop('disabled', true);

    var currentParam = queryStringParam + (filterSelector.prop('checked') ? 'false' : 'true');
    var newParam = queryStringParam + (filterSelector.prop('checked') ? 'true' : 'false');

    const promises = [];
    $('.m-pager--dynamic').each(function() {
        const linkSelector = $(this).find('li').eq(1).children(pageClass).eq(0);
        const updatedHref = linkSelector.attr('href').replace(currentParam, newParam);
        linkSelector.attr('href', updatedHref)

        var status = getPage(linkSelector);
        promises.push(status);
    });

    $.when.apply($, promises).done(function() {
        filterSelector.prop('disabled', false);
    });
};

const init = () => {
    if (window.location.href.endsWith("/agents/home")) {
        filterSelector.on('change', twoWeeksOnly);
    }
};

export default init;
